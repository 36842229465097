*{
    margin: 0;
    padding-top: 50px;
    font-family: sans-serif;
    box-sizing: border-box;
}

.shop{
    width: 100%;
    padding: 20px 30px;
}

.shop h2{
    text-transform: uppercase;
    letter-spacing: 1px;
    letter-spacing: 1px;
    color: #232323;
    font-size: 32px;
    font-weight: 300;
}

.shop p{
    margin-top: 5px;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 600;
}

.shop .container{
    max-width: 100%;
    display: flex;
    margin-top: 20px;
}

.shop .container .left_box{
    width: 25%;
}

.shop .container .left_box .category {
    border: 3px solid #f6f6f6;
    width: 250px;
    margin-top: 2px;
}

.shop .container .left_box .category  .header{
    margin-top: 2px;
    max-width: 100%;
    padding: 10px 20px;
    background: #f6f6f6;
}

.shop .container .left_box .category h3{
    margin-top: 2px;
    font-size: 16px;
    color: #232323;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.shop .container .left_box .category  .box{
    margin-top: 10px;
    width: 100%;
    padding: 10px 20px;
}

.shop .container .left_box .category .box ul{
    max-width: 100%
}

.shop .container .left_box .category .box ul li{
    list-style: none;
    margin-left: 10px;
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #8a8a8a;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.5s;
}

.shop .container .left_box .category .box ul li:hover{
    color: #232323;
}

.shop .container .left_box .banner{
    margin-top: 20px;
    width: 250px;
    
}

.shop .container .left_box .banner img{
    width: 100%;
    object-fit: cover;
    
}

.shop .container .right-box{
    width: 74%;
}

.shop .container .right-box .banner{
    max-width: 100%;
}

.shop .container .right-box .banner .img_box img{
    width: 100%;
    height: 100%;
    object-fit: contain;
} 

.shop .container .right-box .product_box{
    max-width: 100%;
    padding: 10px 10px;
}

.shop .container .right-box .product_box h2{
    color: #233223;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 22px;
    margin-top: 10px;
}

.shop .container .right-box .product_box .product_container{
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space;
}

.shop .container .right-box .product_box .product_container .box{
    padding: 10px 10px;
    width: 270px;
    height: 430px;
    overflow: scroll;
    border: 3px solid #f6f6f6;
    margin-left: 15px;
    margin-top: 20px;
}

.shop .container .right-box .product_box .product_container .box .img_box{
    display: flex;
}

.shop .container .right-box .product_box .product_container .box .img_box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.shop .container .right-box .product_box .product_container .box .img_box .icon{
    display: flex;
    flex-direction: column;
    padding:  10px 10px;
    margin-top: 10px;
    margin-left: 45px;
    transition: 0.5s;
}

.shop .container .right-box .product_box .product_container .box .img_box .icon li{
    list-style: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px 10px;
    margin-top: 10px;
    color: #8a8a8a; 
    background: #fff;
    cursor: pointer;
    transition: 0.5s;
}

.shop .container .right-box .product_box .product_container .box .img_box .icon li:hover{
    color: #fff;
    background: #cd1e76;
}

.shop .container .right-box .product_box .product_container .box:hover .img_box .icon{
    margin-left: -60px;
}

.shop .container .right-box .product_box .product_container .box .detail{
    margin-top: 10px;
    width: 100%;
}

.shop .container .right-box .product_box .product_container .box .detail h3{
    text-align: center;
    color: #232323;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.shop .container .right-box .product_box .product_container .box .detail p{
    margin-top: 5px;
    text-align: center;
    color: #cd1e76;
    font-size: 16px;
}
.shop .container .right-box .product_box .product_container .box .detail button{
    margin-top: 10px;
    margin-left: 30%;
    padding: 10px 20px;
    border: none;
    outline: none;
    color: #232323;
    background: #fed700;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
}

.shop .container .right-box .product_box .product_container .box .detail button:hover{
    color: #fed700;
    background: #232323;
}

@media(max-width:850px){

}

@media(max-width:600px){
    .shop h2{
        margin-top: 10px;
        font-size: 20px;
        margin-bottom: 15px;
    }

    .shop .container .left_box .banner img{
        width: 70%;
        margin-left: 10px;

    }
    .shop .container .right-box .banner{
        margin-top: 550px;
        margin-left: -1px;
    }

.shop .container .right-box .product_box{
    width: 800px;
}

.shop .container .right-box .product_box h2{
    margin-left: -22px;
}

.shop .container .right-box .product_box .product_container{
    margin-left: -40px;

}

.shop .container .right-box .product_box .product_container .box .detail button{
   margin-left: 22px;
}
}

.product_detail{
    width: 900px;
    height: 500px;
    position: fixed;
    top: 100px;
    left: 390px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24)0px 3px 8px;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    padding: 20px 30px;
}

.product_detail .close_btn
{
    float: right;
    border: none;
    outline: none;
    background: none;
    color: #cd1e76;
    font-size: 22px;
    cursor: pointer;
    z-index: 1000;

}

.product_detail .container{
    width: 100%;
    display: flex;
}

.product_detail .container .img_box
{
    padding: 20px 20px;
    background: #f6f6f6;
    border-radius: 10px;
}

.product_detail .container .img_box img
{
    width: 290px;
    height: 370px;
}

.product_detail .container .info{
    margin-top: 30px;
    margin-left: 60px;
}

.product_detail .container .info h4
{
    color: #8a8a8a;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}

.product_detail .container .info h2
{
    margin-top: 30px;
    color: #232323;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
}

.product_detail .container .info p
{
    margin-top: 10px;
    color: #8a8a8a;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
}

.product_detail .container .info h3
{
    margin-top: 10px;
    font-size: 24px;
    color: #cd1e76;
}

.product_detail .container .info button 
{
    margin-top: 10px;
    padding: 10px 30px;
    outline: none;
    background: none;
    background: #cd1e76;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
}

.image_scroll {
    position: relative;
    z-index: 9999; /* Ensure it's above other elements */
    display: flex;
    justify-content: space-between; /* Center buttons and thumbnails */
    padding: 20px;
    /* background-color: rgba(255, 255, 255, 0.9);  */
    margin-top: -40px;
}

.thumbnail_container {
    display: flex;
    overflow: auto;
}

.thumbnail {
    width: 60px;
    height: 50px;
    margin: 0 5px;
    cursor: pointer;
    border: 2px solid transparent;
}

.thumbnail:hover {
    border: 2px solid #cd1e76;
}

.prev, .next {
    background-color: black ; /* Bright color for testing */
    color: white ;
    position: absolute ; /* Ensure they are visible on screen */;
    top: -170%;
    transform: translateY(-50%);
    z-index: 1000 ; /* Make sure they are not hidden by other elements */
    display: block ; /* Force them to be shown */
    width: 50px ; /* Increase button size for visibility */
    height: 50px ;
}

.prev {
    left: -25px; /* Position to the left */
}

.next {
    right: -25px; /* Position to the right */
}
/* Small screens (mobile) */

@media (max-width: 768px) {
    .container .left_box .category .box{
        cursor: pointer;
        pointer-events: auto;
    }
    .container .left_box .category .box li{
        padding: 15px 10px; /* Adjust padding for more clickable area */
        margin-bottom: 15px; /* Adjust margin for spacing */
        z-index: 10;
        position: relative;
    }
    .shop .container {
      flex-direction: column;
    }
    .shop .container .left_box {
      width: 100%;
    }
    .shop .container .right-box {
      width: 100%;
      margin-top: -520px;
      margin-left: 0;
      margin-right: 0;
    }
    .shop .container .right-box .product_box{
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        width: 100%;
        margin-left: 15px;
        padding: 0;
        margin-right: -10px;
    }
    .shop .container .right-box .product_box .product_container{
        max-width: none;
        width: 100%;
        overflow: visible;
        padding: 0;
        margin-left: -30px;
        margin-right: -10px;
        flex-grow: 1;
        flex-basis: 100%;
        padding: 0;
    }
    .shop .container .right-box .product_box .product_container .box{
        width: calc(100% - 10px);
        margin-left: 5px;
        margin-top: 20px;
        width: 47%;
        height: 340px;
        left: -30px;
        display: flex;
        flex-wrap: wrap;
    }
    .product_detail{
    width: 350px;
    height: 420px;
    position: fixed;
    top: 100px;
    left: 10px;   
    overflow: scroll;
    }
    .product_detail .container .img_box img
    {
        width: 320px;
        height: 390px;
        margin-left: -15px;
        margin-top: -14px;
    }
    .product_detail .container .img_box
    {
        margin-left: -20px;
        height: 220px;
        width: 180px;
        margin-top: -40px;
        
    }
    .product_detail .container .info{
        margin-top: -30px;
        margin-left: 20px;
    }
    
    .product_detail .container .info h4
    {
        color: #8a8a8a;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
    }
    
    .product_detail .container .info h2
    {
        margin-top: 20px;
        color: #232323;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
    }
    
    .product_detail .container .info p
    {
        margin-top: 10px;
        color: #8a8a8a;
        font-size: 11px;
        letter-spacing: 1px;
        font-weight: 600;
    }
    
    .product_detail .container .info h3
    {
        /* margin-top: -100px; */
        font-size: 19px;
        color: #cd1e76;
        margin-left: -180px;
    }
    
    .product_detail .container .info button 
    {
        margin-top: 170px;
        margin-left: 20px;
        margin-right: 0;
        margin-bottom: 0;
        padding: 5px 10px;
        outline: none;
        background: none;
        background: #cd1e76;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }
    .product_detail .close_btn{
        z-index: 1000;
        margin-right: -20px;
        position: sticky;
        top: 0;
    }
    .prev, .next {
        background-color: transparent ; /* Bright color for testing */
        border: solid 1px #232323;
        color: black ;
        position: absolute ; /* Ensure they are visible on screen */;
        top: -190%;
        transform: translateY(-50%);
        z-index: 1000 ; /* Make sure they are not hidden by other elements */
        display: block ; /* Force them to be shown */
        width: 40px ; /* Increase button size for visibility */
        height: 40px ;
    }
    .image_scroll {
      
        margin-top: 25px;
    }
    .prev {
    left: -25; /* Position to the left */
    }

    .next {
        right: -25px; /* Position to the right */
    }
}
  
  /* Medium screens (tablet) */
  @media (min-width: 600px) and (max-width: 920px){
    .shop{
        margin-top: 40px;
    }
    .shop .container {
      flex-direction: row;
    }
    .shop .container .left_box {
      width: 30%;
    }
    .shop .container .right-box {
      width: 70%;
      top: 170px;
    }
    .shop .container .right-box .product_box .product_container .box{
        height: 380px;
        margin-right: 4px;
        width: calc(50% - 10px);
        margin-left: 5px;
        width: 47%;
        display: flex;
        flex-wrap: wrap;
    }

    .shop .container .right-box .product_box{
        margin-top: 520px;
    }

    .shop .container .right-box .product_box .product_container{
        margin-left: -130px;
        width: 100%;
        margin-right: 0;
        flex-grow: 1;
        flex-basis: 100%;
    }
    
    .product_detail{
        width: 700px;
        height: 500px;
        margin-left: -350px;
        top: 70px;

    }
  }