*{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}

.cart{
    width: 100%;
    padding: 20px 30px;
}

.cart h3{
    font-size: 32px;
    text-transform: uppercase;
    color: #232323;
    letter-spacing: 1px;
    font-weight: 300;
}

.cart .empty_cart{
    width: 100%;
    align-items: center;
}

.cart .empty_cart h2{
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    color: #232323;
    letter-spacing: 1px;
}

.cart .empty_cart button{
    margin-left: 45%;
    padding: 10px 30px;
    margin-top: 10px;
    background: #fed700;
    color: #cd176c;
    border: none;
    outline: none;
    border: 1px solid #fed700;
    font-weight: 600;
    cursor: pointer;
}

.cart .container{
    max-width: 100%;
    padding: 10px 20px;
}

.cart .container .box{
    display: flex;
    width: 100%;
    border: 2px solid #f6f6f6;
    height: 250px;
    margin: 10px;
}

.cart .container .box .img_box img{
    width: 270px;
    height: 100%;
}

.cart .container .box .detail{
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-left: 30px;
}

.cart .container .box .detail .info{
    margin-top: 40px;
    margin-left: 60px;
}

.cart .container .box .detail .info h4{
    text-transform: uppercase;
    font-size: 14px;
    color: #8a8a8a;
}

.cart .container .box .detail .info h3{
    margin-top: 10px;
    color: #232323;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

.cart .container .box .detail .info p{
    margin-top: 10px;
    color: #232323;
    text-transform: uppercase;
}

.cart .container .box .detail .quantity{
    margin-top: 65px;
    margin-left: 70px;
    display: flex;
}

.cart .container .box .detail .quantity button{
    padding: 10px 20px;
    color: #232323;
    border: none;
    outline: none;
    background: #fed700;
    font-weight: 600;
    height: 40px;
    font-size: 16px;
    cursor: pointer;
}

.cart .container .box .detail .quantity input{
    height: 40px;
    padding: 10px 20px;
    background: none;   
    border: none;
    outline: none;
    border: 2px solid #fed700;
    color: #cd176c;
    font-size: 14px;
    font-weight: 600;
    width: 100px;
}

.cart .container .box .detail .icon{
    margin-top: 65px;
    margin-left: 60px;
}

.cart .container .box .detail .icon li{
    list-style: none;
    background: #cd176c;
    padding: 10px 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.cart .bottom{
    margin-left: 600px;
}

.cart .bottom .Total h4{
    font-size: 22px;
    color: #cd176c;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: -23px;

}

.cart .bottom button{
    padding: 10px 30px;
    border: none;
    outline: none;
    background: none;
    background: #fed700;
    color: #232323;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
}

@media (max-width: 480px) {
    .cart h3{
        font-size: 20px;
    }
    .cart .container{
        padding: 5px 10px;
        overflow: hidden;
    }
    .cart .container .box{
        width: 100%;
        height: 150px;
        margin: 10px;
    }
    .cart .container .box .img_box img{
        width: 100px;
        height: 100%;
    }
    .cart .container .box .detail{
        margin-top: -30px;
        margin-left: -40px;
    }
    .cart .container .box .detail .info h4{
        font-size: 11px;
    }
    
    .cart .container .box .detail .info h3{
        margin-top: 5px;
        font-size: 12px;
    }
    
    .cart .container .box .detail .info p{
        margin-top: 5px;
        font-size: 13px;
    }
    .cart .container .box .detail .quantity{
        margin-top: 140px;
        margin-left: -80px;
        display: flex;
    }
    
    .cart .container .box .detail .quantity button{
        padding: 5px 20px;
        height: 30px;
        font-size: 16px;
    }
    
    .cart .container .box .detail .quantity input{
        height: 30px;
        padding: 10px 20px;
        font-size: 14px;
        width: 70px;
    }
    .cart .container .box .detail .icon{
        margin-top: 30px;
        margin-left: -12px;
    }
    
    .cart .container .box .detail .icon li{
        padding: 5px 5px;
        font-size: 18px;
    }
    .cart .bottom{
        margin-left: 80px;
    }
    
    .cart .bottom .Total h4{
        font-size: 18px;
        margin-left: 0px;
    
    }
    
    .cart .bottom button{
        padding: 10px 30px;
        margin-top: 18px;
    }
    .cart .empty_cart button{
        margin-left: 100px;
    }

}

@media (min-width: 600px) and (max-width: 920px){
    .cart{
        margin-top: 40px;
    }
    .cart .container{
        padding: 5px 10px;
        overflow: hidden;
    }
    .cart .container .box{
        width: 45%;
        height: 150px;
        margin: 10px;
    }
    .cart .container .box .img_box img{
        width: 100px;
        height: 100%;
    }
    .cart .container .box .detail{
        margin-top: -30px;
        margin-left: -40px;
    }
    .cart .container .box .detail .info h4{
        font-size: 11px;
    }
    
    .cart .container .box .detail .info h3{
        margin-top: 5px;
        font-size: 12px;
    }
    
    .cart .container .box .detail .info p{
        margin-top: 5px;
        font-size: 13px;
    }
    .cart .container .box .detail .quantity{
        margin-top: 140px;
        margin-left: -80px;
        display: flex;
    }
    
    .cart .container .box .detail .quantity button{
        padding: 5px 20px;
        height: 30px;
        font-size: 16px;
    }
    
    .cart .container .box .detail .quantity input{
        height: 30px;
        padding: 10px 20px;
        font-size: 14px;
        width: 70px;
    }
    .cart .container .box .detail .icon{
        margin-top: 30px;
        margin-left: -12px;
    }
    
    .cart .container .box .detail .icon li{
        padding: 5px 5px;
        font-size: 18px;
    }
    .cart .bottom{
        margin-left: 80px;
    }
    
    .cart .bottom .Total h4{
        font-size: 18px;
        margin-left: 0px;
    
    }
    
    .cart .bottom button{
        padding: 10px 30px;
        margin-top: 18px;
    }
}