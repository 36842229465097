
*{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: myFont;
    src: url('font/font2.ttf') format('truetype');;
}

.header{
    width: 100%;
    /* position: sticky;
    top: 0; */
    background: #fff;
    z-index: 1;
    overflow-x: hidden;
}

.header .top_header{
    max-width: 100%;
    display: flex;
    padding: 10px 10px;
    background: #f6f7f8;
}

.header .top_header  .icon{
    margin-left: 20px;
    color: #ababab;
    font-size: 19px;
}

.header .top_header .info p{
    margin-left: 10px;
    color: #8a8a8a;
}

.header .mid_header{
    display: flex;
    padding: 20px 20px;
    max-width: 100%;
    justify-content: space-between;
}

.header .mid_header .navicon{
    display: none;
}


.header .mid_header .log{
    font-size: 25px;
    color: 	#4A0404;
    text-decoration: none;
    font-weight: 600;
    margin-top: -10px;
}

.header .mid_header .log .letter{
    margin-left: 60px;
    margin-top: -56px;
    font-family: myFont;

}

.header .mid_header .log .letter span{
    font-family: myFont;
    color: #fed700;
}

.header .mid_header .logo img{
    cursor: pointer;
    object-fit: cover;
    height: 60px;
    width: 66px;
    border-radius: 50%;
}

.logo2 {
    display: none;
}


.header .mid_header .search_box input{
    padding: 10px 10px;
    outline: none;
    background: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 250px;
    border: none;
    background: #f6f7f8;
}

.header .mid_header .search_box button{
     padding: 10px 10px;
     border-top-right-radius: 5px;
     border-bottom-right-radius: 5px;
     border: none;
     outline: none;
     width: 50px;
     background: #cd1e76;
     color: #fff;
     cursor: pointer;
     margin-right: 30px;
     transition: 0.5s;
     font-weight: 600;
}

.header .mid_header .search_box button:hover{
    background: #fed700;
   
    color: #cd1e76;
}

.header .mid_header .user{
    display: flex;
    margin-right: 30px;
}


.header .mid_header .user .icon{
    margin-right: 10px;
}

.header .mid_header .user .btn button{
    border: none;
    outline: none;
    font-size: 16px;
    background: none;
    cursor: pointer;
}

.header .last_header{
    width: 100%;
    padding: 10px 10px;
    display: flex;
    background: #232323;
    justify-content: space-between;
    flex-wrap: wrap;
}

.header .last_header .user_profile{
    display: flex;
    margin-top: 10px;
}

.header .last_header .user_profile2{
    display: none;
}

.header .last_header .user_profile .icon{
    padding: 10px 10px;
    background: #fff;
    border-radius: 10px;
}

.header .last_header .user_profile .info{
    margin-left: 10px;

}

.header .last_header .user_profile .info h2{
    color: #fff;
    font-size: 18px;
}

.header .last_header .user_profile .info p{
    color: #ababab;
    font-size: 14px;
}

.header .last_header .nav{
    margin-top: 15px;
}

.header .last_header .nav ul{
    display: flex;
}

.header .last_header .nav ul li{
    list-style: none;
}

.header .last_header .nav ul li .link{
    margin-right: 20px;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: 0.5s;
}

.header .last_header .nav ul li .link:hover{
    color: #fed700;
}

.header .last_header .offer{
    margin-right: 20px;
    padding: 10px 20px;
    background: #fed700;
    border-radius: 10px;
}


.header .last_header .offer p{
    margin-top: 5px;
    text-transform: uppercase;
    color: #232323;
    font-weight: 600;
    font-size: 14px;
}

/* Add this to your existing CSS file */

.header .hamburger {
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
    width: 25px;
    height: 25px;
    background: transparent;
    border: none;
    z-index: 10;
}

.header .hamburger div {
    width: 25px;
    height: 3px;
    background: #fff;
    border-radius: 5px;
    transition: 0.3s;
}

.header .nav ul {
    display: flex;
}

@media(max-width:950px)
{
    .header .mid_header .navicon{
        margin-bottom: -5px;
        display: block;
    }

    .header .mid_header .navicon .navopen{
        margin-top: 5px;
        font-size: 22px;
    }

    .header .mid_header .navicon .closenav{
        margin-top: 5px;
        font-size: 22px;
    }

    .header .mid_header .search_box{
        width: 100%;
        background: #fff;
        position: absolute;
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header .mid_header .search_box input{
        padding: 10px 10px;
        outline: none;
        background: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 80%;
        border: none;
        background: #f6f7f8;
    }
    
    .header .mid_header .search_box button{
         padding: 10px 10px;
         border-top-right-radius: 5px;
         border-bottom-right-radius: 5px;
         border: none;
         outline: none;
         width: 50px;
         background: #cd1e76;
         color: #fff;
         cursor: pointer;
         margin-right: 30px;
         transition: 0.5s;
         font-weight: 600;
    }
    
    .header .mid_header .search_box button:hover{
        background: #fed700;
       
        color: #cd1e76;
    }

    
    .header .mid_header .user .icon{
        border: none;
        font-size: 16px;
    }

    .header .mid_header .log{
        font-size: 24px;
        margin-top: 4px;
    }
    .header .mid_header .logo img{
        height: 55px;
        width: 40px;
        margin-top: -10px;
        margin-bottom: -10px;
     }


    .header .mid_header .log .letter{
        margin-top: -46px;
    }

    .header .last_header{
        margin-top: 50px;
        flex-direction: column;
        display: none;
    }

    .header .last_header.active{
        display: block;
    }
    
    .header .last_header .nav ul{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    
    .header .last_header .nav ul li{
        margin-top: 20px;
    }
    
    
}

@media(max-width:600px){
    .header .mid_header .user{
        margin-right: -7px;
    }

    .header .mid_header .search_box{
        margin-top: 37px;
        left: 15px;
    }

    .header .mid_header .navicon{
        margin-left: -10px;
    }

    .header .mid_header .logo img{
        height: 55px;
        width: 40px;
        margin-top: -20px;
     }

     .header .mid_header .log{
        margin-top: 4px;
        margin-left: 15px;
        margin-right: -10px;
    }

    .header .mid_header .log .letter{
        margin-top: -40px;
        margin-left: 45px;
        font-size: 20px;
    }
     
}


/* Toggle hamburger animation */
/* .header .hamburger.active div:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.header .hamburger.active div:nth-child(2) {
    opacity: 0;
}

.header .hamburger.active div:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
} */

/* @media (max-width: 768px) {
    .header .mid_header {
        flex-direction: column;
        align-items: center;
    }

    .header .last_header {
        flex-direction: column;
        align-items: center;
    }

    .header .last_header .nav ul {
        flex-direction: column;
        display: none;
        width: 100%;
        text-align: center;
    }

    .header .last_header .nav ul.show {
        display: flex;
    }

    .header .last_header .offer {
        margin-top: 10px;
    }

    .header .hamburger {
        display: flex;
        left: 20px;
    }

    .logo{
        display: none;
    }

    .btn{
        display: none;
    }

    .icon{
        display: none;
    }

    .last_header{
        margin-top: -36px;
    }

    .logo2{
     display: flex;
     width: 10px;
     margin-right: 350px;
    margin-bottom: -50px;
    margin-top: 13px;
    }

    .logo2 img{
        width: 100px;
      
    }
} */
/* Add this to your existing CSS file */

/* Media query to hide the search bar on screens smaller than 1024px */
/* @media (max-width: 1024px) {
    .header .mid_header .search_box {
        display: none;
    }

    .header .mid_header .logo img {
        width: 80px; 
        height: auto;
    }

    .header .mid_header {
        flex-direction: column;
        align-items: center;
    }

    .header .last_header {
        flex-direction: column;
        align-items: center;
    }

    .header .last_header .nav ul {
        flex-direction: column;
        display: none;
        width: 100%;
        text-align: center;
    }

    .header .last_header .nav ul.show {
        display: flex;
    }

    .header .last_header .offer {
        margin-top: 10px;
        display: none;
    }


    .header .hamburger {
        display: flex;
        margin-left: 320px;
        margin-top: 20px;
    }

    .header .last_header .user_profile {
        display: none; 
    }

    .header .nav ul.show .user_profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .header .nav ul.show .user_profile .info h2,
    .header .nav ul.show .user_profile .info p {
        font-size: 14px; 
    }

    .header .nav ul.show .user_profile .icon {
        margin-bottom: 10px;
    }

    
} */
