*
{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}
.contact
{
    padding: 20px 40px;
    width: 100%;
}
.contact .container
{
    padding: 10px 20px;
    max-width: 100%;
}
.contact .container .form
{
    width: 400px;
    background: #f6f6f6;
    padding: 20px 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 0px auto;
    border-top: 3px solid #fed700;
    border-radius: 5px;
}
.contact .container .form h2
{
    color: #cd1e7c;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 600;
}
.contact .container .form .box
{
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}
.contact .container .form .box .lable h4
{
    margin-left: 30px;
    color: #232323;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
}
.contact .container .form .box .input input
{
    margin-right: 16px;
    border: none;
    outline: none;
    background: none;
    border-bottom: 2px solid #8a8a8a;
    padding: 10px 20px;
}
.contact .container .form .box .input textarea
{
    margin-right: 30px;
    border: none;
    outline: none;
    background: none;
    border-bottom: 2px solid #8a8a8a;
    padding: 10px 20px;
    /* resize: none; */
}
.contact .container .form button
{
    margin-top: 20px;
    margin-left: 30px;
    padding: 10px 30px;
    color: #232323;
    border: none;
    outline: none;
    background: none;
    background: #fed700;
    cursor: pointer;
}

@media (max-width: 680px) {
    .contact .container .form {
        width: 350px;
         margin-left: -40px;
        padding: 10px 4px;
    }

 

}