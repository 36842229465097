* {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;

}

.home {
    margin-top: 15px;
    width: 100%;
    overflow-x: hidden;
    /* Prevents horizontal scrolling */

}

.home .top_banner {
    max-width: 100%;
    width: 100%;
    height: 340px;
    /* Adjust based on the image's aspect ratio */
    background: url('image/banner.jpeg');
    /* come edit later*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 50px 60px;
}

.home .top_banner .content {
    width: 100%;
    position: relative;
    top: 20%;
    padding: 10px 50px;
}


.home .top_banner .content h3 {
    text-transform: capitalize;
    color: #cd1e76;
    font-weight: 100;
    letter-spacing: 1px;
}

.home .top_banner .content h2 {
    text-transform: uppercase;
    color: #fed700;
    letter-spacing: 1px;
    font-size: 42px;
    margin-top: 10px;
}

.home .top_banner .content p {
    color: #FFFEF4;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.home .top_banner .content .link {
    padding: 10px 30px;
    background: #fed700;
    color: #232323;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.5s;
}

.home .top_banner .content .link:hover {
    color: #fed700;
    background: #cd1e76;
}

.home .trending {
    padding: 20px 30px;
    width: 100%;
}

.home .trending .container {
    max-width: 100%;
    display: flex;
}

.home .trending .container .left_box {
    padding: 10px 20px;
    width: 100%;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically (if needed) */
}
 

.home .trending .container .left_box .header {
    display: flex;
    max-width: 100%;
    padding: 10px 10px;
    background: #f6f7f8;
    justify-content: space-between;
}

.home .trending .container .left_box .header .heading h2 {
    text-transform: uppercase;
    font-size: 16px;
    color: #232323;
    margin-top: 5px;
    cursor: pointer;
}

.home .trending .container .left_box .header .cate {
    display: flex;
    margin-top: 5px;
}

.home .trending .container .left_box .header .cate h3 {
    text-transform: capitalize;
    margin-right: 10px;
    color: #232323;
    font-size: 14px;
    cursor: pointer;
    transition: 0.5s;

}

.home .trending .container .left_box .header .cate h3:hover {
    color: #cd1e76;
}

.home .trending .container .left_box .products {
    max-width: 100%;
}

.home .trending .container .left_box .products .container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically (if needed) */
}

.home .trending .container .left_box .products button {
    margin-top: 20px;
    margin-left: 44%;
    padding: 10px 30px;
    background: none;
    outline: none;
    border: none;
    color: #fff;
    font-weight: 600;
    background: #cd1e76;
    cursor: pointer;
}

.home .trending .container .left_box .products .container .box {
    width: 195px;
    height: 345px;
    /* padding: 10px 10px; */
    border: 6px solid #f6f7f8;
    margin-top: 20px;
    margin-left: 30px;
    overflow: hidden;
    border-radius: 5px;
}

.home .trending .container .left_box .products .container .box .img_box {
    display: flex;
}

.home .trending .container .left_box .products .container .box .img_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home .trending .container .left_box .products .container .box .img_box .icon {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-top: 10px;
    transition: 0.5s;
}

.home .trending .container .left_box .products .container .box:hover .img_box .icon {
    margin-left: -28%;
}

.home .trending .container .left_box .products .container .box .img_box .icon .icon_box {
    padding: 10px 10px;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.24)0px 3px 8px;
    z-index: 1;
    background: #fff;
    color: #cd1e76;
    transition: 0.5s;
    cursor: pointer;
}

.home .trending .container .left_box .products .container .box .img_box .icon .icon_box:hover {
    color: #fff;
    background: #cd1e76;
}

.home .trending .container .left_box .products .container .box .info {
    padding: 10px 10px;
    margin-left: 10px;
}

.home .trending .container .left_box .products .container .box .info h3 {
    text-transform: uppercase;
    font-size: 14px;
    color: #232323;
    font-weight: 300;
    letter-spacing: 1px;
}

.home .trending .container .left_box .products .container .box .info p {
    color: #cd1e76;
    margin-top: 5px;
    font-size: 14px;
    letter-spacing: 1px;
}

.home .trending .container .left_box .products .container .box .info .btn {
    margin-top: 10px;
    align-items: center;
    padding: 10px 20px;
    margin-left: 20px;
    border: none;
    background: none;
    outline: none;
    background: #fed700;
    color: #232323;
    text-transform: capitalize;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s;
}

.home .trending .container .left_box .products .container .box .info .btn:hover {
    background: #cd1e76;
    color: #f6f7f8;
}

.home .trending .container .right_box {
    padding: 10px 20px;
    width: 24%;
    display: flex;
    flex-direction: column;
}

.home .trending .container .right_box .right_container {
    max-width: 100%;
}

.home .trending .container .right_box .right_container .testimonial {
    width: 100%;
    border: 4px solid #f6f7f8;
}

.home .trending .container .right_box .right_container .testimonial .head {
    padding: 10px 10px;
    width: 100%;
    background: #f6f7f8;
}

.home .trending .container .right_box .right_container .testimonial .head h3 {
    text-align: center;
    color: #232323;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 10px;
    letter-spacing: 1px;
}

.home .trending .container .right_box .right_container .testimonial .detail {
    padding: 10px 20px;
}

.home .trending .container .right_box .right_container .testimonial .detail .img_box img {
    margin-top: 40px;
    margin-left: 50px;
}

.home .trending .container .right_box .right_container .testimonial .detail .info {
    margin-top: 10px;
}

.home .trending .container .right_box .right_container .testimonial .detail .info h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    color: #232323;
}

.home .trending .container .right_box .right_container .testimonial .detail .info h4 {
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    color: #cd1e76;
    font-weight: 300;
    text-transform: uppercase;
}

.home .trending .container .right_box .right_container .testimonial .detail .info p {
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
    color: #232323;
    font-size: 14px;
    margin-bottom: 20px;
}

.home .trending .container .right_box .right_container .newsletter {
    width: 100%;
    background: #f6f7f8;
    margin-top: 20px;
    padding: 12px 12px;
}

.home .trending .container .right_box .right_container .newsletter .head {
    margin-top: 10px;
    width: 100%;
}

.home .trending .container .right_box .right_container .newsletter .head h3 {
    text-align: center;
    color: #232323;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
}

.home .trending .container .right_box .right_container .newsletter .form {
    margin-top: 10px;
    width: 100%;
}

.home .trending .container .right_box .right_container .newsletter .form p {
    text-align: center;
    text-transform: capitalize;
    color: #8a8a8a;
}

.home .trending .container .right_box .right_container .newsletter .form input {
    margin-top: 10px;
    width: 100%;
    padding: 10px 10px;
    background: none;
    outline: none;
    border: none;
    border: 1px solid #8a8a8a;
    color: #232323;
}

.home .trending .container .right_box .right_container .newsletter .form button {
    margin-top: 10px;
    margin-left: 60px;
    padding: 10px 30px;
    border: none;
    background: none;
    outline: none;
    background: #fed700;
    color: #232323;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
}

.home .trending .container .right_box .right_container .newsletter .form .icon_box {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.home .trending .container .right_box .right_container .newsletter .form .icon_box .icon {
    padding: 10px 10px;
    border-radius: 50%;
    border: 1px solid #cd1e76;
    color: #cd1e76;
    margin-bottom: 30px;
    cursor: pointer;
    transition: 0.5s;
}

.home .trending .container .right_box .right_container .newsletter .form .icon_box .icon:hover {
    color: #fff;
    background: #cd1e76;
}

.home .banners {
    padding: 20px 30px;
    width: 100%;
}

.home .banners .container {
    max-width: 100%;
    display: flex;
}

.home .banners .container .left_box {
    width: 24%;
}

.home .banners .container .left_box .box {
    margin-top: 10px;
}

.home .banners .container .right_box {
    width: 75%;
}

.home .banners .container .right_box .top {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}


.home .banners .container .right_box .bottom {
    max-width: 100%;
    margin-top: 10px;
}

.home .banners .container .right_box .bottom img {
    width: 100%;
    object-fit: cover;
}

.home .product_type {
    padding: 20px 30px;
    width: 100%;
}


.home .product_type .container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.home .product_type .container .box {
    width: 360px;
    height: 490px;
    border: 3px solid #f6f7f8;
    overflow: hidden;
}

.home .product_type .container .box .header {
    width: 100%;
    height: 50px;
    background: #f6f7f8;
    padding: 10px 10px;
}

.home .product_type .container .box .header h2 {
    color: #232323;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
}

.home .product_type .container .box .productbox {
    width: 100%;
    height: 110px;
    padding: 10px 10px;
    border: 1px solid #f6f7f8;
    display: flex;
}

.home .product_type .container .box .productbox .img-box img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.home .product_type .container .box .productbox .detail {
    margin-left: 30px;
}

.home .product_type .container .box .productbox .detail h3 {
    font-size: 14px;
    color: #8a8a8a;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.home .product_type .container .box .productbox .detail p {
    margin-top: 5px;
    color: #cd1e76;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 14px;
}

.home .product_type .container .box .productbox .detail .icon {
    margin-top: 10px;
    display: flex;
}

.home .product_type .container .box .productbox .detail .icon button {
    background: none;
    outline: none;
    border: none;
    padding: 10px 10px;
    border: 2px solid #f6f7f8;
    margin-left: 10px;
    border-radius: 50%;
    color: #8a8a8a;
    cursor: pointer;
    transition: 0.5s;
}

.home .product_type .container .box .productbox .detail .icon button:hover {
    background: #f6f7f8;
    color: #232323;
}

.product_detail {
    width: 900px;
    height: 500px;
    position: fixed;
    top: 100px;
    left: 190px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24)0px 3px 8px;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    padding: 20px 30px;
    z-index: 1000;
    overflow-y: auto;
    /* Add scroll for large content */
}

.product_detail .close_btn {
    float: right;
    border: none;
    outline: none;
    background: none;
    color: #cd1e76;
    font-size: 22px;
    cursor: pointer;
    z-index: 1000;

}

.product_detail .container {
    width: 100%;
    display: flex;
}

.product_detail .container .img_box {
    padding: 20px 20px;
    background: #f6f6f6;
    border-radius: 10px;
}

.product_detail .container .img_box img {
    width: 290px;
    height: 370px;
}

.product_detail .container .info {
    margin-top: 30px;
    margin-left: 60px;
}

.product_detail .container .info h4 {
    color: #8a8a8a;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}

.product_detail .container .info h2 {
    margin-top: 30px;
    color: #232323;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
}

.product_detail .container .info p {
    margin-top: 10px;
    color: #8a8a8a;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
}

.product_detail .container .info h3 {
    margin-top: 10px;
    font-size: 24px;
    color: #cd1e76;
}

.product_detail .container .info button {
    margin-top: 10px;
    padding: 10px 30px;
    outline: none;
    background: none;
    background: #cd1e76;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 850px) {
    .home {
        margin-top: 45px;
    }



    .home .top_banner {
        background-size: 100% 100%;
        /* min-height: 50vh;  */
        padding: 30px;
    }

    .home .top_banner .content {
        padding: 10px;
    }

    .home .top_banner .content h2 {
        font-size: 24px;
    }

    .home .trending .container {
        flex-direction: row;
    }

    .home .trending .container .left_box {
        width: 100%;
        padding: 10px;
    }

    .home .trending .container .left_box .header {
        width: 400px;
    }

    .home .trending .container .right_box {
        margin-left: 12%;
        width: 100%;
    }

    .home .trending .container .left_box .products .container {
        margin-left: 20px;
    }

    .home .trending .container .left_box .products .container .box {
        margin-left: 0;
        width: 48.5%;
        height: 342px;
        margin-right: 6px;
    }

}




@media (max-width: 391px) {
    .home .top_banner {
        padding: 20px;
        background-size: 100% 75%;
        width: 100%;
        /* min-height: 100vh; */
    }

    .home .top_banner .content h2 {
        font-size: 20px;
    }

    .home .trending .container .left_box .header {
        margin-top: -50px;
        width: 300px;
        margin-left: 20px;
    }

    .home .trending .container .left_box .header .heading {
        margin-left: 50px;
    }

    .home .trending .container .left_box .header .cate {
        display: none;
    }

    .home .trending .container .right_box {
        display: none;
    }

    .home .trending .container .left_box {
        width: 100%;
        padding: 10px;
        margin-left: -15px;

    }

    .home .trending {
        width: 570px;
        margin-left: -20px;
    }

    .home .trending .container .left_box .products{
        width: 100%;
    }

    .home .trending .container .left_box .products .container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        margin-left:-10%;
        padding: 10px; 

    }

    .home .trending .container .left_box .products .container .box {
        margin-left: 0;
        width: 40%;
        height: 340px;
        left: -30px;
        margin-right: 6px;
    }

    .home .banners .container .left_box .box {
        display: none;
    }

    .home .trending .container .left_box .products .container .box img {
        /* margin-left: -15px; */
        width: 100%;
    }

    .home .trending .container .left_box .products button {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .img3 {
        display: none;
    }

    .img4 {
        display: none;
    }

    .img1 {
        width: 100px;
        height: 150px;
    }

    .img5 {
        margin-left: 33px;
        height: 150px;
        margin-top: -10px;
    }

    .home .trending .container .left_box .products .container .box .info .btn {
        margin-left: 0px;
    }

    .home .trending .container .left_box .products button {
        margin-left: 100px;
    }

    .home .product_type .container {
        display: grid;
        gap: 1rem;
    }

    .home .product_type .container .box {
        width: 330px;
    }

    .home .trending .container .left_box .products .container .box .img_box .icon {
        margin-left: 5%;
    }

    .home .trending .container .left_box .products .container .box:hover .img_box .icon {
        margin-left: -28%;
    }

    .home {
        width: 100%;
    }

}

@media  (min-width: 392px) and (max-width: 600px) {
    .home .top_banner {
        padding: 20px;
        background-size: 100% 75%;
        width: 100%;
        /* min-height: 100vh; */
    }

    .home .top_banner .content h2 {
        font-size: 20px;
    }

    .home .trending .container .left_box .header {
        margin-top: -50px;
        width: 300px;
        margin-left: 20px;
    }

    .home .trending .container .left_box .header .heading {
        margin-left: 50px;
    }

    .home .trending .container .left_box .header .cate {
        display: none;
    }

    .home .trending .container .right_box {
        display: none;
    }

    .home .trending .container .left_box {
        width: 100%;
        padding: 10px;
        margin-left: -15px;

    }

    .home .trending {
        width: 570px;
        margin-left: -20px;
    }

    .home .trending .container .left_box .products{
        width: 100%;
    }

    .home .trending .container .left_box .products .container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        margin-left:-7%;
        padding: 10px; 

    }

    .home .trending .container .left_box .products .container .box {
        margin-left: 0;
        width: 44%;
        height: 370px;
        left: -30px;
        margin-right: 6px;
    }

    .home .banners .container .left_box .box {
        display: none;
    }

    .home .trending .container .left_box .products .container .box img {
        /* margin-left: -15px; */
        width: 100%;
    }

    .home .trending .container .left_box .products button {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .img3 {
        display: none;
    }

    .img4 {
        display: none;
    }

    .img1 {
        width: 100px;
        height: 150px;
    }

    .img5 {
        margin-left: 33px;
        height: 150px;
        margin-top: -10px;
    }

    .home .trending .container .left_box .products .container .box .info .btn {
        margin-left: 0px;
    }

    .home .trending .container .left_box .products button {
        margin-left: 100px;
    }

    .home .product_type .container {
        display: grid;
        gap: 1rem;
    }

    .home .product_type .container .box {
        width: 330px;
    }

    .home .trending .container .left_box .products .container .box .img_box .icon {
        margin-left: 5%;
    }

    .home .trending .container .left_box .products .container .box:hover .img_box .icon {
        margin-left: -28%;
    }

    .home {
        width: 100%;
    }

}